<template>
  <div class="row">
    <b-overlay
        :show="loading"
        :opacity="opacity"
        id="overlay-background"
        spinner-variant="success"
        variant="white"
        blur="2px"
        rounded="sm"
    >
      <div class="col-12 heading pt-3">
        <h2 class="text-success">{{ getTitle() }}</h2>
        <router-link to="/history" class="btn btn-success hidden">
          {{ getButtonLabel() }}
        </router-link>
      </div>
      <div class="col-12 pt-3">
        <span id="filters"></span>
        <table class="elements" aria-describedby="filters">
          <tr v-for="element in elements" :key="element.name">
            <th :id="element.name"
                :class="issetError(element.name) ? 'label error' : 'label'"
                v-if="(element.label && (getElementDisplayForaAquiring() || element.type !== 'date_period')) && element.type !== 'table'&& element.type !== 'tabs'">
              {{ (element.type === 'select' && element.data.length === 0)  ? element.no_data : (element.label + ':')}}
            </th>
            <th :id="element.name" class="label" v-if="!element.label"/>
            <td v-if="element.type === 'select'">
              <b-form-select v-model="requestData[element.name]"
                             :class="{'is-invalid': issetError(element.name)}"
                             @change="changeRequestData(element.name, $event)"
                             v-if="element.data.length > 0">
                <b-form-select-option v-for="(option, index) in element.data"
                                      :key="index"
                                      :value="option.value">
                  {{ option.label }}
                </b-form-select-option>
              </b-form-select>
              <view-error v-if="issetError(element.name)" :errors="errors[element.name]"/>
            </td>
            <td v-if="element.type === 'date_period' && getElementDisplayForaAquiring()">
              <div id="datepicker">
                <CustomDateRangePicker
                        :config="config"
                        v-model="datePeriod"
                ></CustomDateRangePicker>
              </div>
              <view-error v-if="issetError('date_begin')" :errors="errors.date_begin"/>
              <view-error v-if="issetError('date_end') && !issetError('date_begin')" :errors="errors.date_end"/>
            </td>
            <EmailModalLegal
                v-if="element.type === 'modal'"
                :element="element"
                v-on:change="setModalParam(element.parameter, $event)"
            >
            </EmailModalLegal>
            <td v-if="element.type === 'date' && getElementDisplayForaAquiring()">
              <datepicker
                      ref="datepicker"
                      v-model="date"
                      name="date" id="date"
                      :format="dateFormatter(date, 'DD.MM.YYYY')"
                      :language="state.language"
                      :disabled-dates="state.disabledDates"
                      input-class="date-select"
                      :required="true">
                <div slot="beforeCalendarHeader" class="calender-header">
                  <div class="calender-header-year">
                    {{getDatePickerHeaderYear()}}
                  </div>
                  <div class="calender-header-text">
                    {{getDatePickerHeaderText()}}
                  </div>
                </div>
              </datepicker>
              <view-error v-if="issetError('date')" :errors="errors.date"/>
            </td>
            <td v-if="element.type === 'input'">
              <b-form-input v-model="requestData[element.name]" :placeholder="elementName"
                            @update="inputUpdate($event, element.name)" v-on:keyup="inputDeleteSpace($event)"></b-form-input>
              <view-error v-if="issetError(element.name)" :errors="errors[element.name]"/>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-12" v-if="tabs">
        <div class="label">{{tabs.label + ':'}}</div>
        <b-tabs fill>
          <b-tab @click="changeTab(tab)"
                 v-for="(tab, i) in defaultTabs"
                 :key="i">
            <template #title>
              <span>{{ tabs[tab].label}}</span>
            </template>
            <div v-for="element in tabs[tab]" :key="element.name">
              <CustomTable
                      v-if="element.type === 'table' && !!element.data"
                      :display="!!element"
                      :isCheckbox="isCheckbox"
                      :showTableData="showTableData"
                      :table="element"
                      :requestData="requestData"
                      :requestParam="paramsByTableType[tabs.name].requestParam"
                      v-bind:selectAccounts="selectAccounts"
                      v-on:change="setTableRequestParam($event)"
              ></CustomTable>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-12" v-if="table">
        <span class="table-no-data" v-if="!table.data && (table.no_data || table.no_data_closed)">{{table.no_data_closed || table.no_data}}</span>
        <div class="col-12 pt-3" v-if="!table.data && !table.no_data && requestData.certificate_type">
          <h4>Вiдсутня можливiсть обрати рахунок для замовлення довiдки</h4>
        </div>
        <CustomTable
                v-if="!!table && !!table.data"
                :display="!!table.data"
                :isCheckbox="isCheckbox"
                :showTableData="showTableData"
                :table="table"
                :requestParam="paramsByTableType[table.name].requestParam"
                :requestData="requestData"
                v-bind:selectAccounts="selectAccounts"
                v-on:change="setTableRequestParam($event)"
        ></CustomTable>
      </div>
      <div class="col-12 send-buttons" v-if="showButtons()">
        <b-button variant="success" @click="sendRequest">Сформувати</b-button>
        <b-button variant="success" @click="processRequest" v-if="elements.email_form">Відправити на пошту</b-button>
        <b-button variant="outline-secondary"
          v-if="certificatesOptions[requestData.certificate_type] ?
            !certificatesOptions[requestData.certificate_type].hideCancelBtn :
            !certificatesOptions[requestData.certificate_type]"
          @click="clearRequest">
          Скасувати
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>

import RequestService from '../services/request.service';
import ViewError from '../../components/ViewError';
import moment from 'moment';
import CustomDateRangePicker from '../../physical/components/date-range-picker/DateRangePicker';
import {DATE_RANGE_CONFIG} from '../../physical/enums/date-range.config';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import CustomTable from '../components/custom-table/CustomTable';
import Datepicker from 'vuejs-datepicker';
import {uk} from 'vuejs-datepicker/dist/locale';
import EmailModalLegal from "../components/email-modal/EmailModal";
import Vue from "vue";

export default {
  components: {
    EmailModalLegal,
    CustomDateRangePicker,
    Datepicker,
    ViewError,
    CustomTable
  },
  data() {
    return {
      loading: true,
      isCheckbox: true,
      opacity: '1',
      initData: {},
      requestData: {},
      elements: [],
      additionalElements: null,
      elementName: '',
      all_accounts: 'not_all_selected',
      table: null,
      tabs: null,
      selectAccounts: [],
      errors: {},
      dateStart: '',
      dateFinish: '',
      config: DATE_RANGE_CONFIG,
      paramsByTableType: {
        loans: {
          request: 'getLoans',
          dataName: 'agreements',
          requestParam: 'contract_ref'
        },
        loan: {
          request: 'getLoans',
          dataName: 'agreements',
          requestParam: 'contract_ref'
        },
        accounts: {
          request: 'getAccounts',
          dataName: 'accounts',
          requestParam: 'account'
        },
        payment_for_balance_accounts: {
          request: 'getAccounts',
          dataName: 'account',
          requestParam: 'account',
          openTable: 'open_deposits_table',
          closedTable: 'closed_deposits_table',
          requestOpenTable: 'open',
          requestClosedTable: 'closed'
        },
        account: {
          request: 'getAccounts',
          dataName: 'accounts',
          requestParam: 'account'
        },
        credits: {
          request: 'getLoans',
          dataName: 'contract_refs',
          requestParam: 'contract_ref',
          openTable: 'open_credits_table',
          closedTable: 'closed_credits_table',
          requestOpenTable: 'openAgreements',
          requestClosedTable: 'closedAgreements'
        },
        terminals: {
          request: 'getTerminals',
          dataName: 'terminals',
          requestParam: 'terminal_id',
          openTable: 'open_table',
          closedTable: 'closed_table',
          requestOpenTable: 'open_terminals',
          requestClosedTable: 'closed_terminals'
        },
        cards: {
          request: 'getCards',
          dataName: 'pan',
          requestParam: 'pan',
          openTable: 'open_cards_table',
          closedTable: 'closed_cards_table',
          requestOpenTable: 'openCards',
          requestClosedTable: 'closedCards'
        },
        deposits: {
          request: 'getDeposits',
          dataName: 'account',
          requestParam: 'account',
          openTable: 'open_deposits_table',
          closedTable: 'closed_deposits_table',
          requestOpenTable: 'openDeposits',
          requestClosedTable: 'closedDeposits'
        }
      },
      showTableData: true,
      isReverseTableData: false,
      paramForSortTable: '',
      defaultTabs: ['tab1', 'tab2'],
      certificatesOptions: {
        cloan_status: {
          hideCancelBtn: true
        },
        full_loan_repayment_certificate: {
          hideCancelBtn: true
        },
      },
      state: {
        language: uk,
        disabledDates: {
          to: new Date(1992, 0, 1),
          from: new Date(),
        },
      },
      dateValue: new Date(),
    }
  },
  computed: {
    indeterminate() {

      return (!!(this.selectAccounts.length)) && (this.selectAccounts.length !== this.table && this.table.data);
    },
    datePeriod: {
      get() {
        return [this.dateStart, this.dateFinish]
      },
      set(value) {
        if (value[0] && value[0] !== null) {
          this.dateStart = value[0];
          delete this.errors.date_begin;
          this.requestData['date_begin'] = this.customFormatter(this.dateStart);
        } else if (this.elements.date_period) {
          Vue.set(this.errors, 'date_begin',this.elements.date_period?[this.elements.date_period.error]:['']);
        }
        if (value[1] && value[1] !== null) {
          this.dateFinish = value[1];
          delete this.errors.date_end;
          this.requestData['date_end'] = this.customFormatter(this.dateFinish);
        } else if (this.elements.date_period) {
          Vue.set(this.errors, 'date_end', this.elements.date_period?[this.elements.date_period.error]:['']);
        }
      }
    },
    date: {
      get() {
        return this.dateValue
      },
      set(value) {
        this.dateValue = this.customFormatter(value);
        this.requestData['date'] = this.customFormatter(value);
        delete this.errors['date'];
      }
    },
  },
  mounted() {
    RequestService.getInitData()
        .then(({data}) => {
          this.initData = data;
          this.setAdditionalRequestData(data);
        })
        .catch(() => {

        })
        .finally(() => {
          this.loading = false;
        });
  },
  methods: {
    setTableRequestParam(data) {
      this.selectAccounts = data;
    },
    changeTab(tab) {
      this.activeTab = tab;
      this.selectAccounts = [];
    },
    getElementDisplayForaAquiring() {
      if (this.requestData.certificate_type === 'acquiring_statement') {
        return this.elements.terminals ? this.elements.terminals.data.length > 0 : true;
      } else {
        return true;
      }
    },
    inputDeleteSpace: function(event) {
      event.target.value = event.target.value.trim()
    },
    /**
     * Формирование обьекта запроса доп полей
     */
    setAdditionalRequestData(data) {
      if (data.create_certificate && data.create_certificate.elements) {
        this.elements = data.create_certificate.elements;
        for (const element in data.create_certificate.elements) {
          if (Object.prototype.hasOwnProperty.call(data.create_certificate.elements, element)) {
            this.requestData[element] = '';
          }
        }
      }
    },
    /**
     *Получение названия кнопки для перехода в историю запросов
     * @returns {string|*|string}
     */
    getButtonLabel() {
      if (this.initData.elements && this.initData.elements.length) {
        const find = this.initData.elements.find(element => element.name === 'button_journal');

        return find ? find.label : '';
      }

      return '';
    },
    /**
     * Получение заголовка страницы
     * @returns {string|*|string}
     */
    getTitle() {
      if (this.initData.create_certificate) {

        return this.initData.create_certificate.title ? this.initData.create_certificate.title : '';
      }

      return '';
    },
    /**
     * Проверка данных/получение счетов
     * @param key
     * @param e
     */
    changeRequestData(key, e) {
      this.opacity = '0.75';
      delete this.errors[key];
      if (key === 'contractor_attribute') {
        if (e === 'account') {
          this.elementName = '260000000000';
        } else {
          this.elementName = '';
        }
        if (!e) {
          delete this.errors['contractor_attribute_value'];
          this.requestData.contractor_attribute_value = '';
        } else {
          this.inputUpdate(this.requestData.contractor_attribute_value, 'contractor_attribute_value');
        }
      }
      if (key === 'certificate_type') {
        this.accounts = [];
        this.elements = [];
        this.selectAccounts = [];
        this.requestData = {certificate_type: e};
        this.errors = {};
        this.elementName = '';
        this.datePeriod = []
        this.getAdditionElements();
      }
    },
    /**
     * Получение списка доп полей
     */
    getAdditionElements() {
      this.loading = true;
      RequestService.getAdditionElements(this.requestData)
          .then(({data}) => {
            this.elements = {...this.initData.create_certificate.elements, ...data};
            delete this.elements.request_ref;
            delete this.elements.response_ref;
            delete this.elements.result;
            delete this.elements.timestamp;
            this.table = null;
            this.tabs = null;
            this.checkIsTables();
            this.setDefaultElements(this.elements.language);
            this.setDefaultElements(this.elements.withStamp);
            if (this.elements.date) {
              this.requestData.date = this.customFormatter(new Date());
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = !!this.table;
          });
    },
    setDefaultElements(element) {
      if (element) {
        this.requestData[element.name] = element.data[0].value
      }
    },
    /**
     * Проверка на поле "account(s)"
     */
    checkIsTables() {
      for (const key in this.elements) {
        if (Object.prototype.hasOwnProperty.call(this.elements, key)) {
          if (this.elements[key].type === 'table') {
            this.table = this.elements[key];
            this.isCheckbox = this.elements[key].name === 'accounts' || this.elements[key].multiple;
            this.getTableData(this.elements[key].name);
          }
          if (this.elements[key].type === 'tabs') {
            this.tabs = this.elements[key];
            this.isCheckbox = this.elements[key].multiple;
            this.getTableData(this.elements[key].name, this.elements[key].type);
          }
        }
      }
    },
    checkLengthAccounts() {
      return !this.elements.account_table || this.selectAccounts.length > 0;
    },
    /**
     * Получение списка счетов
     */
    getTableData(name, type) {
      this.loading = true;
      RequestService[this.paramsByTableType[name].request](this.requestData)
          .then(({data}) => {
            if (type === 'tabs') {
              const tables = name === 'payment_for_balance_accounts' ? data.accounts : data;
              this.setTabTableData(tables, name);
              if (this.table) this.table.data = data;
            } else {
              this.table.data = data[this.paramsByTableType[name].dataName].length ? data[this.paramsByTableType[name].dataName] : null;
            }
          })
          .catch((err) => {
            this.setTabTableData(null, name);
            if (err.errors && err.errors.message) {
              this.tabs.tab1[this.paramsByTableType[name].openTable].no_data = err.errors.message;
              this.tabs.tab2[this.paramsByTableType[name].closedTable].no_data_closed = err.errors.message;
            }
          })
          .finally(() => {
            this.loading = false;
            this.$forceUpdate();
          });
    },
    setTabTableData(data, name) {
      this.tabs.tab1[this.paramsByTableType[name].openTable].data = data ? data[this.paramsByTableType[name].requestOpenTable] : [];
      this.tabs.tab2[this.paramsByTableType[name].closedTable].data = data ? data[this.paramsByTableType[name].requestClosedTable] : [];
    },
    /**
     * Выбор всех счетов
     * @param checked
     */
    selectAllAccounts(checked) {
      this.selectAccounts = [];
      if (checked === 'all_selected') {
        this.selectAccounts = this.accounts.map(item => item.account);
      }
    },

    /**
     * Формирование обьекта для заказа
     */
    getSendData() {
      const data = {};
      for (const key in this.requestData) {
          data[key] = this.requestData[key];
        }
      if (this.selectAccounts && this.selectAccounts.length) {
        const param = this.table ? (this.paramsByTableType[this.table.name].requestParam + `${this.isCheckbox ? 's' : ''}`) : this.paramsByTableType[this.tabs.name].dataName;
        data[param] = this.selectAccounts;
      }
      return data;
    },
    processRequest() {
      if (this.elements.email_form) {
        this.$bvModal.show('email')
      } else {
        this.sendRequest();
      }
    },
    /**
     * Заказ справки
     */
    sendRequest() {
      this.loading = true;
      const send = this.getSendData();
      RequestService.getDocument(send)
          .then(({data}) => {
            if (data.link) {
              window.open(data.link);
            }
            if (data.message) {
              this.$toast.open({
                message: data.message,
                type: 'success',
              })
            }
            this.selectAccounts = [];
          })
          .catch(err => {
            if (err.errors) {
              this.errors = err.errors;
            }
          })
          .finally(() => {
            this.loading = false;
            delete this.requestData['email'];
          });
    },
    /**
     * Отмена заказа справки
     */
    clearRequest() {
      this.selectAccounts = [];
    },
    /**
     * Проверка на наличие ошибки
     * @param key
     * @returns {boolean}
     */
    issetError(key) {
      if (key === 'date_period') {
        return Object.prototype.hasOwnProperty.call(this.errors, 'date_begin') ||
            Object.prototype.hasOwnProperty.call(this.errors, 'date_end');
      }
      return Object.prototype.hasOwnProperty.call(this.errors, key);
    },
    inputUpdate(value, key) {
      if (key === 'contractor_attribute_value' && (this.requestData['contractor_attribute'] === 'okpo' ||
          this.requestData['contractor_attribute'] === 'account')) {
        delete this.errors['contractor_attribute_value'];
        let maxLength = /^[0-9]{0,10}$/;
        let error = [this.elements.contractor_attribute_value.errors.okpo.max10];
        if (this.requestData['contractor_attribute'] === 'account') {
          maxLength = /^[0-9]{14}$/;
          error = [this.elements.contractor_attribute_value.errors.account.size14];
        }
        if (!maxLength.test(value)) {
          this.errors['contractor_attribute_value'] = error;
        }

      }
    },
    getDate() {
      return new Date();
    },
    customFormatter(date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD');
      }
    },
    getDatePickerHeaderYear() {
      if (this.$refs.datepicker && this.$refs.datepicker.length) {
        return this.dateFormatter(this.$refs.datepicker[0].selectedDate, "YYYY")
      }
      return '-'
    },
    getDatePickerHeaderText() {
      if (this.$refs.datepicker && this.$refs.datepicker.length) {
        return this.dateFormatter(this.$refs.datepicker[0].selectedDate, "dddd D MMMM")
      }
      return '-'
    },
    dateFormatter(date, format) {
      return moment(date).local('uk').format(format);
    },
    setModalParam(parameter, value) {
      this.requestData[parameter] = value;
      this.sendRequest();
    },
    showButtons() {
      return ((this.requestData.certificate_type && !this.table && !this.tabs) || this.selectAccounts.length)
          && Object.entries(this.errors).length === 0 && this.getElementDisplayForaAquiring();
    }
  },
  watch: {
    selectAccounts(newValue) {
      this.all_accounts = (newValue.length === 1) ?
          'all_selected' : 'not_all_selected';
    },
  }
}

</script>

<style lang="scss">
.hidden {
  display: none;
}

table {
  tr {
    th,
    td {
      padding: 0 15px 15px 0;

      select {
        width: 100%;
        min-width: 250px;
        max-width: 300px;
      }

      input {
        width: 100%;
        min-width: 250px;
        max-width: 300px;

        &.form-control[readonly] {
          background-color: #ffffff;
        }
      }

      &.label {
        font-weight: 600;
        min-width: 125px;
        width: auto;

        &.error {
          color: #dc3545;
        }
      }
    }
  }
}

table.table-condensed {
  tr {
    th,
    td {
      padding: 0;

      select {
        width: 100%;
        min-width: auto;
        max-width: 100%;
      }

      input {
        width: 100%;
        min-width: auto;
        max-width: 100%;
      }
    }
  }
}

#datepicker {
  display: flex;
  max-width: 300px;
}

#overlay-background {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.send-buttons {
  button {
    min-width: 200px;
    margin-right: 25px;
    margin-bottom: 40px;
  }
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #007e2b;
  background-color: #007e2b;
}

.chosenAccount {
  font-weight: bold !important;
}

.vue-daterange-picker {
  width: 100%;
}

.date-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.calender-header {
  border-radius: 10px 10px 0 0;
  background-color: #4bd;
  padding: 20px;
  color: white;
  &-year {
    color: rgba(255, 255, 255, 0.7);
  }
  &-text {
    font-size: 24px;
    font-weight: 600;
  }
}

caption {
  caption-side: top;
  color: black;
  font-weight: 600;
}


.header-cell {
  cursor: pointer;
  .sort-arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") center no-repeat;
  }
}

  div.label {
    padding: 0 15px 15px 0;
    font-weight: 600;
  }

  div.b-overlay {
    position: fixed !important;
  }

</style>
