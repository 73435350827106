import axios from 'axios';

export default {
    /**
     * Получение основных данных по заказу справок
     * @returns {Promise<AxiosResponse<any>>}
     */
    getInitData() {

        return axios.get('/legal/init');
    },
    /**
     * Заказ/генерация справки
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getDocument(data) {

        return axios.post('/legal/get-document', data);
    },
    /**
     * Получение списка счетов для звказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAccounts(data) {

        return axios.post('/legal/get-accounts', data);
    },
    /**
     * Получение списка счетов для звказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTerminals(data) {

        return axios.post('/legal/get-terminals', data);
    },
    /**
     * Получение списка счетов для звказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getLoans(data) {

        return axios.post('/legal/get-debt-agreements', data);
    },
    /**
     * Получение списка счетов для звказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCards(data) {

        return axios.post('/legal/get-cards', data);
    },
    /**
     * Получение списка счетов для звказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getDeposits(data) {

        return axios.post('/legal/get-deposits', data);
    },
    /**
     * Получение списка дополнительных полей для заказа
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAdditionElements(data) {

        return axios.post('/legal/get-additional-elements', data);
    }
}
